import React from 'react';
import { StaticCanvas } from "../components/canvas";
import sha1 from 'crypto-js/sha1';

class IdenticonGenerator extends React.Component {
    constructor(props) {
        super(props);
        // The initial seed and currentInput are different so that when the
        // user pressed generate without changing the input, something new
        // is generated.
        this.state = { seed: "Change this value", currentInput: 'Change this value_'};
    }

    generate() {
        this.setState({seed: this.state.currentInput });
        return false;
    }

    render() {
        return (
            <div style={{margin: `20px 0`}}>
                <StaticCanvas width='240px' height='240px' background='#f6f6f6' style={{padding: `20px 0`}}
                draw={(ctx, w, h) => {
                    let hash = sha1(this.state.seed);
                    let blockWidth = w / 8;
                    // Convert the hash, which is represented by 32-bit integers,
                    // to a array of 0's and 1's.
                    let arr = [];
                    for (let word of hash.words) {
                        for (let i=0; i < 32; i++) {
                            arr.push((word >> i) & 1);
                        }
                    }
                    // Extract R, G, B values
                    let r = 0, g = 0, b = 0;
                    for (let i = 0; i < 8; i++) {
                        r += arr[16+i] << i;
                        g += arr[24+i] << i;
                        b += arr[32+i] << i;
                    }
                    for (let i = 0; i < 4; i++) {
                        for (let j = 0; j < 4; j++) {
                            if (arr[i*4 + j] === 1) {
                                ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
                                ctx.fillRect(blockWidth*j, blockWidth*i, blockWidth, blockWidth);
                                ctx.fillRect(w - blockWidth*(j+1), blockWidth*i, blockWidth, blockWidth); // Horizontal flip
                                ctx.fillRect(blockWidth*j, h - blockWidth*(i+1), blockWidth, blockWidth); // Vertical flip
                                ctx.fillRect(w - blockWidth*(j+1), h - blockWidth*(i+1), blockWidth, blockWidth); // Diagonal flip
                            }
                        }
                    }
                }}/>

                <div style={{ margin: `0 auto`, background: `none`, textAlign: `center`}}>
                    <input type="text" style={{ display: `block`, margin: `8px auto`}} defaultValue={ this.state.seed }
                           onChange={ (e) => this.setState({currentInput: e.target.value})}
                           onKeyDown={(e) => {if (e.key === 'Enter') this.generate()}}/>
                    <button type="button" style={{ display: `block`, margin: `8px auto`}}
                            onClick={ () => this.generate()}>Generate Identicon!</button>
                </div>
            </div>
        );
    }
}

export default IdenticonGenerator