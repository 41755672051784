import React from "react"
import styled from "styled-components"


const StyledCanvas = styled.canvas`
    width: 500px;
    height: 300px;
    background: black;
    margin: 12px auto;
    display: block;
    
    @media(max-width:500px) {
        width: 100%;
    }
`;


class PlayableCanvas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {playing: 0};
    }

    /**
     * Hacky way to ensure refresh of child canvas.
     */
    update() {
        this.setState({playing: this.state.playing + 1});
    }

    render() {
        return(
            <div style={{textAlign: `center`, margin: `24px 0`}}>
                <Canvas draw={this.props.draw} init={this.props.init}
                        playing={this.state.playing} duration={this.props.duration}/>
                <button style={{textAlign: `center`}} onClick = { () => {this.update()} }>Play animation</button>
            </div>
        )
    }

}


class Canvas extends React.Component {

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.run = this.run.bind(this);
        // this.init = this.init.bind(this);
    }

    componentDidMount() {
        this.canvas = this.canvasRef.current;
        this.ctx = this.canvas.getContext("2d");
        this.ctx.save();
        this.t = 0;

        this.clear();
    }

    resize() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.canvas.style.width = `${window.innerWidth}px`;
        this.canvas.style.height = `${window.innerHeight}px`;
    }

    render() {
        if (this.props.playing) {
            this.clear();
            this.t = 0;
            this.run();
        }

        return(
            <StyledCanvas ref={this.canvasRef} width={600} height={300}/>
        )
    }

    init() {
        this.props.init(this.ctx, this.canvas.width, this.canvas.height);
    }

    draw() {
        this.props.draw(this.ctx, this.t, this.canvas.width, this.canvas.height);
    }

    clear() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        if (this.props.init !== undefined) {
            this.props.init(this.ctx, this.canvas.width, this.canvas.height);
        }
    }

    run() {
        this.t += 1;
        this.draw();
        if (this.props.duration !== undefined && this.t > this.props.duration) {
            window.cancelAnimationFrame(this.f);
        } else {
            this.f = window.requestAnimationFrame(this.run);
        }
    }
}


const StyledStaticCanvas = styled.canvas`
    margin: 20px auto;
    max-width: 100%;
    width: ${props => props.width};
    height: ${props => props.height};
    background: ${props => props.background ? props.background : `none`};
    display: block;
`;

class StaticCanvas extends React.Component {

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        this.canvas = this.canvasRef.current;
        this.ctx = this.canvas.getContext("2d");
        this.draw();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.ctx.fillStyle = this.props.background ? this.props.background : `none`;
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        this.draw();
    }

    draw() {
        this.props.draw(this.ctx, this.canvas.width, this.canvas.height);
    }

    resize() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.canvas.style.width = `${window.innerWidth}px`;
        this.canvas.style.height = `${window.innerHeight}px`;
    }

    render() {
        return(
            <StyledStaticCanvas ref={this.canvasRef} width={this.props.width}
                                height={this.props.height} background={this.props.background} />
        )
    }
}


export { PlayableCanvas, Canvas, StaticCanvas }
